import { v4 as uuidv4 } from 'uuid'
import {HAS_TOKEN} from '~/store/auth/types.auth'

export default function({ store }) {
  const isAuth = store.getters[`auth/${HAS_TOKEN}`]
  if (isAuth) return false
  const tempUserIsHas = localStorage.getItem('tempUser')
  if (tempUserIsHas) return false
  localStorage.setItem('tempUser', uuidv4())
}
