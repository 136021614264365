import auth from '~/api/auth.api'
import user from '~/api/user.api'
import main from '~/api/main.api'
import tickets from '~/api/tickets.api'
import reviews from '~/api/reviews.api'
import promoCodes from '~/api/promoCodes.api'
import loyalty from '~/api/loyalty.api'
import favorites from '~/api/favorites.api'
import settings from '~/api/settings.api'
import compare from '~/api/compare.api'
import orders from '~/api/orders.api'
import refunds from '~/api/refunds.api'
import cart from '~/api/cart.api'
import newsletter from '~/api/newsletter.api'

const createRepository = ($request, store) => (fn) => fn($request, store)

export default ({ $request, store }, inject) => {
  const repository = createRepository($request, store)

  const repositories = {
    auth: repository(auth),
    user: repository(user),
    main: repository(main),
    tickets: repository(tickets),
    reviews: repository(reviews),
    promoCodes: repository(promoCodes),
    loyalty: repository(loyalty),
    favorites: repository(favorites),
    settings: repository(settings),
    compare: repository(compare),
    orders: repository(orders),
    refunds: repository(refunds),
    cart: repository(cart),
    newsletter: repository(newsletter),
  }

  inject('api', repositories)
}
