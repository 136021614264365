export default ($request) => ({
  async addItemCart(payload) {
    return await $request.$post('/cart', payload)
  },
  async decreaseItemCart(payload) {
    return await $request.$patch('/cart', payload)
  },
  async getItemsCart(payload) {
    return await $request.$get('/cart', {}, {headers: {uuid: payload}})
  },
  async deleteItemCart(id) {
    return await $request.$delete(`/cart/item/${id}`)
  },
  async clearCart() {
    return await $request.$delete('/cart')
  },
  async applyLoyaltyProgram() {
    return await $request.$post('/cart/loyalty-program')
  },
  async deleteLoyaltyProgram() {
    return await $request.$delete('/cart/loyalty-program')
  },
  async applyPromocode(payload) {
    return await $request.$post('/cart/promo-code', payload)
  },
  async deletePromocode() {
    return await $request.$delete('/cart/promo-code')
  },
  async createOrderOnCheckout(payload) {
    return await $request.$post('/cart/checkout', payload)
  },
  async getPaymentMethod() {
    return await $request.$get('/settings/payment-methods')
  },
  async getDeliveryMethod() {
    return await $request.$get('/settings/delivery-methods')
  },
  async cartCheckoutSuccess(payload) {
    return await $request.$post('/cart/checkout/success', payload)
  },
})
