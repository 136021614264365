export const state = () => ({
  constants: {
    ORDER_TYPE_STANDARD: 'Standard',
    ORDER_TYPE_DROPSHIPPING: 'Dropshipping',
    DELIVERY_METHOD_INTERNI_CARGO: 'Interni CARGO',
    DELIVERY_METHOD_DHL: 'DHL',
    PAYMENT_TYPE_FULL_PAYMENT: 'Full payment',
    PAYMENT_TYPE_CLIENT_FULL_PAYMENT: 'Client full payment',
    PAYMENT_TYPE_COMBINED_PAYMENT: 'Combined payment',
  },
  items: [],
})

export const getters = {
  getProductsInCart(state) {
    return state.items
  },
}

export const mutations = {
  SET_CART_PRODUCTS(state, payload) {
    state.items = payload
  },
  SET_CART_PRODUCT(state, payload) {
    const isProduct =  state.items.find((item) => item.slug === payload.slug)
    if(!isProduct) {
      state.items.push(payload)
    }
    // } else {
    //   const idx = state.items.findIndex((item) => item.slug === payload.slug)
    //   state.items[idx] = payload
    // }
  },
  DELETE_CART_PRODUCT(state, slug) {
    state.items = state.items.filter(item => item.slug !== slug)
  },
  CLEAR_CART_PRODUCT(state) {
    state.items = []
  },
}

export const actions = {
  async FETCH_CART_PRODUCTS({ commit }) {
    try {
      const response = await this.$api.cart.getItemsCart(localStorage.getItem('tempUser'))
      if(response.data.length === 0) {
        commit('CLEAR_CART_PRODUCT')
        return
      }
      const items = response.data.items
      const favorites = items.map((item) => {
        return {
          name: item.name,
          picture: item.files[0].url,
          slug: item.slug,
          price: item.sum,
          qty: item.quantity,
        }
      })
      commit('SET_CART_PRODUCTS', favorites)

    } catch (error) {
      // console.log(error)
    }
  },
}
