const api = {
  baseQuery: (url) => `/auth/${url}`,
}

export default ($request) => ({
  login(params) {
    return $request.$post(api.baseQuery('login'), params)
  },
  testToken() {
    return $request.$get(api.baseQuery('test'))
  },
  activateUser(token) {
    return $request.$get(api.baseQuery(`verify/guest/${token}`))
  },
  logout() {
    return new Promise((resolve) => {
      setTimeout(function() {
        resolve('Logout!')
      }, 2000)
    })
  },
  forgot(params) {
    return $request.$post(api.baseQuery('forgot-password'), params)
  },
  recoverPassword(params) {
    return $request.$put(api.baseQuery('recover-password'), params)
  },
  testRecoverPassword(params) {
    return $request.$put(api.baseQuery('test-recover-password'), params)
  },
  register(params) {
    return $request.$post(api.baseQuery('register'), params)
  },
  cancelGoogleConnection() {
    return $request.$post(api.baseQuery('unbind'))
  },
})
