import {  CLEAR_ERROR, ERROR, SET_ERROR } from './types.main'

export const state = () => ({
  error: false,
  loading: true,
  productCount: 1,
})

export const getters = {
  [ERROR]: (state) => state.error,
  getProductCount(state) {
    return state.productCount
  },
}

export const mutations = {
  [SET_ERROR]: (state) => (state.error = true),
  [CLEAR_ERROR]: (state) => (state.error = false),
  loadingState(state, payload) {
    state.loading = payload
  },
  setProductCount(state, payload) {
    state.productCount = payload
  },
}

export const actions = {
  async nuxtServerInit({dispatch, getters}) {
    await dispatch('settings/FETCH_SETTINGS')

    const isHaveUser = getters['auth/HAS_TOKEN']

    if(isHaveUser) {
      await dispatch('user/FETCH_USER')
      await dispatch('favorites/FETCH_FAVORITES')
      await dispatch('compares/FETCH_COMPARES')
      await dispatch('cart/FETCH_CART_PRODUCTS')
    }
  },
}
