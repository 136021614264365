import { FETCH_SETTINGS, SET_SETTINGS } from './types.settings'

export const state = () => ({
  settings: {},
})

export const getters = {}

export const mutations = {
  [SET_SETTINGS]: (state, payload) => (state.settings = payload),
}

export const actions = {
  async [FETCH_SETTINGS]({ commit }) {
    try {
      const response = await this.$api.settings.getSettings()
      commit(SET_SETTINGS, response.data)
    } catch (error) {
      // console.error('Settings state error:', error)
    }
  },
}
