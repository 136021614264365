const api = {
  baseQuery: (url) => `/main/newsletter/${url}`,
}

export default ($request) => ({
  subscribe(params) {
    return $request.$post(api.baseQuery('subscribe'), params)
  },
  unsubscribe(params) {
    return $request.$post(api.baseQuery('unsubscribe'), params)
  },
})
